<template>
  <RsModal
    :title="$t('navbar.menu')"
    modal-window-classes="max-h-full !h-auto"
    class="items-end"
    @dismiss="closeNavbar"
  >
    <RsField
      wrapper-class="mb-4"
      icon="ic-language"
      @click="openAndEditLanguage"
    >
      {{ $t('navbar.changeLanguage') }}
      <template #rightIcon>
        <IcArrowRight class="text-rs-primary" width="7" height="12" />
      </template>
    </RsField>

    <div v-if="!isPublic && !isBackOffice" class="mb-8">
      <LogoutButton @close="closeNavbar" />
    </div>
  </RsModal>
</template>

<script setup lang="ts">
import { useCustomerStore } from '~/stores/customer.store';
import useModal from '~/composables/useModal'
import { IcArrowRight } from '~/components/common/icons'
import LogoutButton from '~/components/navbar/LogoutButton.vue'

const emit = defineEmits(['commit'])

const { isPublic, isBackOffice } = useCustomerStore()
const { handleModal } = useModal()

function closeNavbar () {
  emit('commit', true)
}

const openAndEditLanguage = async () => {
  const { isChanged } = await handleModal('modal-locale-switcher')
  if (isChanged) {
    emit('commit', true)
  }
}
</script>
